// import React, { useState, useEffect, Component } from "react"
// import axios from "axios"
//const http = require("http")
import React, { Component } from "react"

import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"

function KYC() {
  return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Support | RealT</title>
          </MetaTags>
          <Container fluid>
            <h4>Support KYC</h4>
            <div className="App">
              <h2> Search your KYC data </h2>
              <p></p>
              <button>Submit</button>
            </div>
          </Container>
        </div>
      </React.Fragment>
  );
}

export default KYC;
